import React from "react";
import StripePricingTable from "./PricingTable";
export default function PricingPage() {
  return (
    <div className=" hero section is-fullheight">
      {" "}
      <section className="section is-large">
        <div className="title"> Pricing Tiers </div>
        <div>
          <StripePricingTable />
        </div>
      </section>
    </div>
  );
}
