import axios from "axios";

export const getUserSubscription = async (stripe_customer_id, token) => {
  try {
    const response = await axios.get(
      `/api/get_user_sub/${stripe_customer_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.error("Error getting user sub information:", error);
    throw error;
  }
};

export const stopStream = async (username, token) => {
  try {
    axios.get(`/api/stop_stream/${username}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error("Error stopping stream:", error);
    throw error;
  }
};

export const startStream = async (username, token) => {
  try {
    axios.get(`/api/start_stream/${username}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error("Error starting stream:", error);
    throw error;
  }
};

export const addBroadcasterToDB = async (username, token) => {
  try {
    axios.get(`/api/add_user/${username}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.error("Error adding user:", error);
    throw error;
  }
};

export const getSpeechServiceToken = async (token) => {
  try {
    const response = await axios.get(`/api/get_token`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (error) {
    console.error("Error getting speech service token:", error);
    throw error;
  }
};

export const checkUserServiceUsage = async (username, token) => {
  try {
    const response = await axios.get(`/api/limit_check/${username}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.error("Error checking usage of user:", error);
    throw error;
  }
};

export const updateElapsedTime = async (username, customer_id, token) => {
  try {
    const response = await axios.post(`/api/update_elapsed_time/${username}`, {
      customer_id: customer_id,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.error("Error updating users elapsed time:", error);
    throw error;
  }
};
