import React from "react";

const Footer = () => {
  return (
    <footer class="footer mt-auto is-flex-align-items-flex-end">
      <div class="content has-text-centered">
        <p>
          <strong>© 2024 Akhil Svanerud</strong> All Rights Reserved.
        </p>
        <p>
          <a href="mailto: support@churchlingo.se">support@churchlingo.se</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
