import { useEffect, useRef, useState } from "react";

export const DeviceList = () => {
  let audio = useRef([]);

  const [gotDevices, setGotDevices] = useState(false);

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              devices.forEach((device) => {
                if (device.kind === "audioinput") {
                  audio.current.push(device);
                }
              });
            })
            .then(() => {
              console.log(audio.current);
              setGotDevices(true);
            })
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
            });
        })
        .catch((err) => {
          console.log(err);
          setGotDevices(true);
          audio.current = "No Audio";
        });
    }
  }, []);

  return (
    <div>
      <h3>Device List</h3>
      {gotDevices && audio.current !== "No Audio" ? (
        <div>
          <div class="select is-primary">
            <select>
              {audio.current.map((device) => (
                <option value={device.deviceId}>{device.label}</option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <>
          {!gotDevices && audio.current !== "No Audio" ? (
            <progress class="progress is-small is-primary" max="100">
              15%
            </progress>
          ) : (
            <div>No Microphone found</div>
          )}
        </>
      )}
    </div>
  );
};
