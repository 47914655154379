import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

import DashboardButton from "./DashboardButton";

export const NavBarButtons = () => {
  const { isAuthenticated } = useAuth0();
  const [isActive, setisActive] = useState(false);

  const { loginWithRedirect, logout } = useAuth0();

  return (
    <>
      <div className="navbar-brand">
        <a className="navbar-item has-text-weight-semibold" href="/">
          ChurchLingo
        </a>
        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          role="button"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBurger"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarBurger"
        className={`navbar-menu ${isActive ? "is-active" : "is-hidden"}`}
      >
        <div class="navbar-end">
          <a href="/" className="navbar-item">
            Home
          </a>
          <a href="/about-us" className="navbar-item">
            About
          </a>
          <a href="/pricing" className="navbar-item">
            Pricing
          </a>
          <hr class="navbar-divider" />
          <a href="mailto: support@churchlingo.se" class="navbar-item">
            Report an issue
          </a>
          <hr class="navbar-divider" />
          <div class="navbar-item">
            <div class="buttons">
              {!isAuthenticated ? (
                <>
                  <a
                    class="button is-primary"
                    onClick={() =>
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: "signup",
                        },
                      })
                    }
                  >
                    <strong>Sign up</strong>
                  </a>
                  <a
                    class="button is-light"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </a>
                </>
              ) : (
                <a class="button is-warning" onClick={() => logout()}>
                  Log Out
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a href="/" className="navbar-item">
            Home
          </a>

          <a href="/about-us" className="navbar-item ">
            About
          </a>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">More</a>

            <div className="navbar-dropdown">
              <a href="/pricing" className="navbar-item">
                Pricing
              </a>

              <hr class="navbar-divider" />
              <a href="mailto: support@churchlingo.se" class="navbar-item">
                Report an issue
              </a>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              {!isAuthenticated ? (
                <>
                  <a
                    class="button is-primary"
                    onClick={() =>
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: "signup",
                        },
                      })
                    }
                  >
                    <strong>Sign up</strong>
                  </a>
                  <a
                    class="button is-light"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </a>
                </>
              ) : (
                <>
                  <a class="button is-primary" href="/broadcast">
                    Dashboard
                  </a>
                  <a class="button is-warning" onClick={() => logout()}>
                    Log out
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
