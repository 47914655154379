import React from "react";
import { NavBarButtons } from "./NavBarLogin";
import { useLocation } from "react-router-dom";
const Header = () => {
  let location = useLocation();
  return (
    <>
      {!location.pathname.includes("/viewerlanding") && (
        <nav
          class="navbar is-white"
          role="navigation"
          aria-label="main navigation"
        >
          <NavBarButtons />
        </nav>
      )}
    </>
  );
};

export default Header;
